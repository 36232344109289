.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 38px;
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  z-index: 2;
  overflow-y: auto;
  max-height: 114px;
  border: 1px solid black;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  border-radius: 4px;
  overflow: scroll !important;
  overflow-x: hidden !important;
  overflow-y: overlay !important;
  --scrollbar-color: #00000040;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar  {
  display: block;
  width: 16px;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-button {
  display: none;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-track {
  background-color: #00000000;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-track-piece {
  background-color: #00000000;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-thumb {
  background-color: #00000000;
  border: 5px solid transparent;
  border-radius: 24px;
  box-shadow: 4px 0px 0px 4px var(--scrollbar-color) inset;
}

.react-autosuggest__suggestions-container--open::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color);
  border: 0px solid transparent;
  box-shadow: none;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
}
