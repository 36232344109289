@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .inset-scrollbar {
    overflow: scroll !important;
    overflow-x: hidden !important;
    overflow-y: overlay !important;
  }

  .float-scrollbar {
    --scrollbar-color: #00000000;
  }

  .float-scrollbar:hover, .float-scrollbar:focus, .float-scrollbar:focus-within {
    --scrollbar-color: #00000040 !important;
  }

  .float-scrollbar::-webkit-scrollbar {
    display: block;
    width: 16px;
  }
  
  .float-scrollbar::-webkit-scrollbar-button {
    display: none;
  }
  
  .float-scrollbar::-webkit-scrollbar-track {
    background-color: #00000000;
  }
  
  .float-scrollbar::-webkit-scrollbar-track-piece {
    background-color: #00000000;
  }
  
  .float-scrollbar::-webkit-scrollbar-thumb {
    background-color: #00000000;
    border: 5px solid transparent;
    border-radius: 24px;
    box-shadow: 4px 0px 0px 4px var(--scrollbar-color) inset;
  }

  .float-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color);
    border: 0px solid transparent;
    box-shadow: none;
  }

  .break-anywhere {
    overflow-wrap: anywhere;
  }
}
